import React from "react";
import { Toolbar } from "primereact/toolbar";
import { Image } from "primereact/image";

type Props = {};

const Header = (props: Props) => {
  const start = () => {
    return (
      <Image src="seeward.png" alt="logo" width="100" className="p-mr-2" />
    );
  };

  return <Toolbar start={start} style={{width: '75vw', margin: 'auto'}}></Toolbar>;
};

export default Header;
