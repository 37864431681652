import React from 'react';
import logo from './logo.svg';
import Header from './components/header';
import './App.css';
import Blog from './components/blog';
import Flickr from './components/flickr';

function App() {
  return (
    <div className="App">
      <Header />
      <div className='main-panel'>
        <div style={{width: '100%', backgroundColor: 'black'}}>
          <div style={{width: '50%', margin: 'auto'}}>
          <Blog />
          </div>

        </div>

   
      </div>
      
    </div>
  );
}

export default App;
