import React, { useEffect } from 'react'
import { Panel } from 'primereact/panel'
import blog  from './blog.json';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from 'primereact/inputtext';
import {Card} from 'primereact/card';

type Props = {}

const Blog = (props: Props) => {

  const [filterText, setFilterText] = React.useState('');
  const [filteredBlog, setFilteredBlog] = React.useState(blog);
  const [fullBlog, setFullBlog] = React.useState(blog);
  const [totalPosts, setTotalPosts] = React.useState(blog.length);
  const [filteredPosts, setFilteredPosts] = React.useState(blog.length);


  // function to remove any html form a given string
  function removeHtmlTags(str: string) {
    return str.replace(/<[^>]*>?/gm, '');
  }

  useEffect(() => {

    // check if filtertext is empty
    if (filterText === '') {
      setFilteredBlog(fullBlog)
      setFilteredPosts(fullBlog.length)
      return
    }
    // filter the blog Title and Body based on the filterText
    setFilteredBlog(fullBlog.filter((eachOne) => {
      return removeHtmlTags(eachOne.Title).toLowerCase().includes(filterText.toLowerCase()) || removeHtmlTags(eachOne.Body).toLowerCase().includes(filterText.toLowerCase())
    }))
    setFilteredPosts(filteredBlog.length)

  }, [filterText])


  return (
    <Card style={{color: 'white'}}>
    <ScrollPanel style={{width: '100%', height: '50vh', padding: 25, overflowX: 'hidden', margin: 'auto', marginTop: 10, textAlign: 'justify'}}>
      <div style={{display: 'flex'}}>
      <InputText placeholder="Search" onChange={(e) => setFilterText(e.currentTarget.value)} style={{width: '50%', margin: 10, marginLeft: 0}} />
      <p>{filteredPosts} posts</p>
      </div>
      
      {filteredBlog.filter((eachOne)=>{return removeHtmlTags(eachOne.Body) != ''}).map((post, index) => (
        <div key={index}>
          <h2>{removeHtmlTags(post.Title)}</h2>
          <p>{post.date}</p>
          <p>{removeHtmlTags(post.Body)}</p>
          <hr />
        </div>
      )).reverse()}
    </ScrollPanel>
    </Card>
  )
}

export default Blog